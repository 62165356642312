import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export let ImpexHeader = () => {

	const [isSticky, setIsSticky] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	//const history = useHistory();

	const handleScroll = () => {
		setIsSticky(window.pageYOffset >= getOffset());
		setIsScrolled(window.pageYOffset > 15);
	};

	const getOffset = () => {
		const navbarElement = document.querySelector('.navbar');
		return navbarElement ? navbarElement.getBoundingClientRect().top : 0;
	};

	const handleToggleClick = () => {
		const navbarCollapse = document.getElementById("navbarCollapse")?.classList.contains("show");

		if (navbarCollapse) {
			// Handle the collapse logic
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);


	return (

		<>
			<div className={`navbar navbar-expand-lg navbar-dark fixed-top sticky scrolled`}>
				<div className="container">
					<div className="w-50">
						<a href="/impex" className="navbar-brand d-flex align-items-center bg-transparent">
							<h1 className="text-center">
								<img
									className="img-fluid Logo_theX me-3"
									src="../assets/images/impexlogo.png"
									alt="thexlinez logistics"
								/>
							</h1>
						</a>
					</div>

					<button
						className="navbar-toggler ml-auto"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarCollapse"
						aria-controls="navbarCollapse"
						aria-expanded="false"
						aria-label="Toggle navigation"
						style={{ color: '#a40000' }}
						onClick={handleToggleClick}
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse Navcss" id="navbarCollapse">
						<div className="navbar-nav mx-auto pe-4 py-3 py-lg-0">
							<Link className="nav-item nav-link" to={'/impex'}>Home</Link>
							<Link className="nav-item nav-link" to={'/impex'}>About Us</Link>
							<Link className="nav-item nav-link" to={'/impex'}>Our Services</Link>
							<Link className="nav-item nav-link" to={'/impex'}>Contact Us</Link>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}