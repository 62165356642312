import { Component, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from '../../logistics/Header';
import HomeContent from './HomeContent';
import { Footer } from '../../logistics/Footer';
import LogoSvg from '../../LogoSvg';
import { Link } from 'react-router-dom';

const MyAdvFn = () => {
    /* const [visible, setVisible] = useState(true);

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;

    return (

        <div className='fixed-top'>
            <div className='row'>
                <div className='col-md-6'>
                    Our new import & export website <Link to='/impex/home'>www.thexlinez.impex.com</Link>
                </div>
                <div className='col-md-6'>
                    <span className='close-btn' onClick={handleClose}>&times;</span>
                    count us
                </div>
            </div>
        </div>

    ) 
        
    




    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Import Export Ad Card</title>
    <link rel="stylesheet" href="styles.css">
</head>

<style>



</style>

<body>
    <div class="ad-card">
        <img src="your-image-url.jpg" alt="Import Export" class="ad-image">
        <div class="ad-content">
            <h2 class="ad-title">Global Import Export Solutions</h2>
            <p class="ad-description">Seamlessly connect with suppliers and customers worldwide. Reliable, fast, and secure import-export services tailored to your needs.</p>
            <a href="http://your-site.com" class="ad-button">Learn More</a>
        </div>
    </div>
</body>
</html>

    
    */

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const hasShownPopup = localStorage.getItem('hasShownPopup');
        if (!hasShownPopup) {
            const timer = setTimeout(() => {
                setVisible(true);
                localStorage.setItem('hasShownPopup', 'true');
            }, 15000); // 15000 ms = 15 seconds

            return () => clearTimeout(timer); // Clean up the timer
        }
    }, []);

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div className='fixed-top popup'>
            <div className='row'>
                <div className='col-md-6'>
                    Our new import & export website <Link to='/impex/home'>www.thexlinez.impex.com</Link>
                </div>
                <div className='col-md-6'>
                    <span className='close-btn' onClick={handleClose}>&times;</span>
                    count us
                </div>
            </div>
        </div>
    );
}

class HomeLayout extends Component {

    render() {

        return (
            <div>
                {/* <MyAdvFn /> */}

                <Header />
                <HomeContent />
                <Footer />

                <a href="https://api.whatsapp.com/send?phone=919566650400" target="_blank" className="whatsapp-btn">
                    <i className="bi bi-whatsapp"></i>
                </a>

            </div>
        );
    }
}

const LogisticsLayoutFn = () => {
    // Add a loading spinner
    const [spinnerActivityFlag, setSpinnerActivityFlag] = useState<number>(0);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {

        const timer1 = setTimeout(() => {
            setIsLoaded(true);
            setSpinnerActivityFlag(1);
            //console.log("1");
        }, 4600);


        const timer2 = setTimeout(() => {
            setIsLoaded(true);
            setSpinnerActivityFlag(0);
            //console.log("2");
        }, 100);

        window.scrollTo({ top: 0, behavior: 'smooth' });

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    return (
        <div>

            {spinnerActivityFlag === 0 && (
                <div
                    className="contentoverlay d-flex align-items-center justify-content-center bg-white"
                    style={{ height: '100%' }}
                >
                    <div className={isLoaded ? 'active' : ''}>
                        <LogoSvg />
                    </div>
                </div>
            )}

            <HomeLayout />

        </div>
    );
}

export default LogisticsLayoutFn;