import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Footer extends Component {

  render = () => {
    return (
      <footer className="flag site-footer m-3">
        <div className="container">

          <div className="row pt-5 d-flex justify-content-between">

            <div className="col-md-12">
              <div className="row">

                <div className="col-md-2"></div>

                <div className="col-md-3">
                  <h2 className="footer-heading mb-4">Get In Touch</h2>

                  <div className="d-flex mb-1">
                    <div className="me-4">
                      <img src="\assets\svg\landmake.svg" alt="" />
                    </div>
                    <div>
                      <h5>Address</h5>
                      <address className="mb-0 text-secondary">
                      19/87 D1, First Floor, Pulluvilai, Erumbukadu Post, Nagercoil, Kanniyakumari, Tamil Nadu-629 004.
                      </address>
                    </div>
                  </div>

                  <div className="d-flex mb-1">
                    <div className="me-4">
                      <img src="\assets\svg\calling.svg" alt="" />
                    </div>
                    <div>
                      <h5>Phone</h5>
                      <p className="mb-0">
                        <a className="link-secondary text-decoration-none"
                          href="tel:+919566650400"
                        >(+91) 95666 50400</a>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="me-4">
                      <img src="\assets\svg\emailing.svg" alt="" />
                    </div>
                    <div>
                      <h5>E-Mail</h5>
                      <p>
                        <a className="link-secondary text-decoration-none" href="mailto:info@thexlinez.com">
                          info@thexlinez.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <h2 className="footer-heading mb-4">Features</h2>
                  <ul className="list-unstyled">
                    <li><Link className="nav-link mt-1" to={'/aboutus'}> About Us </Link></li>
                    <li><Link className="nav-link mt-1" to={'/service'}>Our Service</Link></li>
                    <li><Link className="nav-link mt-1" to={'/contactus'}>Contact Us</Link></li>
                  </ul>
                </div>

                <div className="col-md-4 ml-auto">
                  <div className="">
                    <h2 className="footer-heading mb-4">Follow Us</h2>

                    <a className="btn btn-square rounded-circle bg-light me-2" href="https://www.facebook.com/people/The-X-Linez/61556681904909/" target='_blank'>
                      <i className="fab fa-facebook-f"></i>
                    </a>

                    <a className="btn btn-square rounded-circle bg-light me-2" href="https://www.instagram.com/x_linez/" target='_blank'>
                      <i className="fab fa-instagram"></i>
                    </a>

                    {/* <a className="btn btn-square rounded-circle bg-light me-2" href="">
                    <i className="fab fa-x-twitter"></i>
                  </a> */}

                    <a className="btn btn-square rounded-circle bg-light me-2" href="https://www.linkedin.com/in/the-x-linez-5a1b2830a/" target='_blank'>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>

                  <div className="text-start" >
                    <img
                      className="img-fluid w-50"
                      src="../assets/images/txllogo2.png"
                      alt="WWW.thexlinez.com"
                    />
                  </div>
                </div >

              </div>
            </div>


          </div >

          <div className="row text-center">
            <div className="col-md-12">
              <div className="pt-4">
                <p style={{ color: '#a40000' }}>
                  Copyright &copy; {new Date().getFullYear()} | All rights{' '}
                  <a href="">X Linez Software</a>
                </p>
              </div>
            </div>
          </div>

        </div>
      </footer>

    )
  }
}
