import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LogisticsLayoutFn from './components/layouts/logistics/HomeLayout';
import ImpexLayoutFn from './components/layouts/impex/ImpexLayout';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {/* Logistics Layout */}
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/*" element={<LogisticsLayoutFn />} />
          
          {/* Impex Layout */}
          <Route path="/impex" element={<Navigate to="/impex/home" />} />
          <Route path="/impex/*" element={<ImpexLayoutFn />} />

          {/* 404 bugfix: redirect to home page */}
          <Route path="*" element={<Navigate to="/home" />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
