import { Component, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import LogoSvg from '../../LogoSvg';
import ImpexContent from './ImpexContent';
import { ImpexHeader } from '../../impex/ImpexHeader';
import { ImpexFooter } from '../../impex/ImpexFooter';

class ImpexLayout extends Component {
    render() {

        return (
            <div>
                <ImpexHeader />
                <ImpexContent />
                <ImpexFooter />

                <a href="https://api.whatsapp.com/send?phone=919566650400" target="_blank" className="whatsapp-btn">
                    <i className="bi bi-whatsapp"></i>
                </a>

            </div >
        );
    }
}

const ImpexLayoutFn = () => {
    // Add a loading spinner
    const [spinnerActivityFlag, setSpinnerActivityFlag] = useState<number>(0);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {

        const timer1 = setTimeout(() => {
            setIsLoaded(true);
            setSpinnerActivityFlag(1);
            //console.log("1");
        }, 4600);


        const timer2 = setTimeout(() => {
            setIsLoaded(true);
            setSpinnerActivityFlag(0);
            //console.log("2");
        }, 100);

        window.scrollTo({ top: 0, behavior: 'smooth' });

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    return (
        <div>

            {spinnerActivityFlag === 0 && (
                <div
                    className="contentoverlay d-flex align-items-center justify-content-center bg-white"
                    style={{ height: '100%' }}
                >
                    <div className={isLoaded ? 'active' : ''}>
                        <LogoSvg />
                    </div>
                </div>
            )}

            <ImpexLayout />

        </div>
    );
}

export default ImpexLayoutFn;