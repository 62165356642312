import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ImpexHome } from '../../impex/ImpexHome';

let ImpexContent = () => {
        let navigate = useNavigate()
        let param = useParams()

        return (
            <div>
                <Routes>

                    {/* impex */}
                    <Route path='/home' element={<ImpexHome />}></Route>

                </Routes>
            </div>
        );
    }

export default ImpexContent;