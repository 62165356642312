import '../logo.css'

const LogoSvg = () => {

    return (
        <div className="logo">
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 300.000000 306.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,306.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                >
                    <path
                        d="M1275 1764 c-483 -653 -878 -1194 -879 -1203 -1 -9 7 -16 18 -16 18 0 82 85 1383 1844 230 311 392 539 389 547 -3 8 -12 14 -19 14 -8 0 -409 -534 -892 -1186z"
                        fill="#A40000"
                        className="svg-elem-1"
                    ></path>

                    <path
                        d="M1795 1724 c-483 -653 -878 -1194 -879 -1203 -5 -68 63 19 915 1169 483 652 877 1192 876 1200 -1 8 -9 16 -18 18 -10 2 -297 -378 -894 -1184z"
                        fill="#0C2462"
                        className="svg-elem-2"
                    ></path>

                    <path
                        d="M376 2891 c-8 -13 854 -1045 864 -1034 4 4 53 70 110 146 l102 137 -304 380 -303 380 -232 0 c-130 0 -234 -4 -237 -9z"
                        fill="#A40000"
                        className="svg-elem-3"
                    ></path>

                    <path
                        d="M2324 2823 c-30 -42 -36 -53 -27 -53 4 0 18 14 30 31 l22 32 9 -39 c6 -22 13 -51 16 -66 8 -36 15 -35 49 8 35 43 30 57 -6 14 l-26 -30 -13 62 c-8 34 -19 63 -24 65 -6 2 -19 -9 -30 -24z"
                        fill="#0C2462"
                        className="svg-elem-4"
                    ></path>

                    <path
                        d="M2201 2669 c-13 -17 -20 -34 -15 -38 5 -4 29 -22 52 -41 l44 -33 24 29 c31 39 30 57 -2 20 -23 -26 -27 -27 -41 -13 -15 14 -15 18 1 41 9 14 12 26 7 26 -5 0 -15 -10 -23 -22 -13 -20 -17 -21 -32 -9 -14 12 -15 16 -3 30 21 24 29 41 20 40 -4 0 -19 -14 -32 -30z"
                        fill="#0C2462"
                        className="svg-elem-5"
                    ></path>

                    <path
                        d="M2110 2544 c0 -4 17 -22 38 -39 l37 -33 -52 10 c-85 17 -94 5 -34 -43 29 -23 55 -39 58 -35 4 4 -2 13 -13 20 -64 47 -64 47 4 40 37 -4 62 -3 62 3 0 5 -17 23 -37 39 -47 38 -63 47 -63 38z"
                        fill="#0C2462"
                        className="svg-elem-6"
                    ></path>

                    <path
                        d="M1981 2382 c6 -16 93 -82 99 -76 3 3 -11 19 -30 36 -43 36 -74 54 -69 40z"
                        fill="#0C2462"
                        className="svg-elem-7"
                    ></path>

                    <path
                        d="M1870 2246 c0 -2 23 -21 51 -42 l52 -38 23 29 c13 16 21 32 18 34 -3 3 -14 -5 -24 -19 l-19 -25 -40 33 c-35 28 -61 40 -61 28z"
                        fill="#0C2462"
                        className="svg-elem-8"
                    ></path>

                    <path
                        d="M1517 1829 c-20 -26 -23 -37 -16 -56 13 -34 12 -34 -17 -27 -21 5 -31 1 -50 -22 -13 -16 -23 -32 -24 -36 0 -3 26 -9 59 -13 l58 -7 18 -59 c10 -32 22 -59 26 -59 4 0 17 14 29 30 19 26 20 35 11 62 -11 30 -11 30 19 25 24 -5 35 -1 55 21 14 15 25 30 25 34 0 7 -38 15 -94 20 -26 3 -31 10 -48 57 -10 30 -20 56 -22 58 -2 2 -15 -10 -29 -28z"
                        fill="#A40000"
                        className="svg-elem-9"
                    ></path>

                    <path
                        d="M1808 1493 c-47 -60 -96 -124 -111 -144 l-27 -36 298 -369 297 -369 235 -3 c128 -1 237 1 242 6 8 8 -828 1020 -844 1021 -4 1 -44 -47 -90 -106z"
                        fill="#A40000"
                        className="svg-elem-10"
                    ></path>

                    <path
                        d="M1013 1157 c-36 -39 -35 -44 18 -83 l52 -38 23 30 c13 16 24 34 24 39 -1 6 -12 -3 -25 -20 -23 -27 -26 -28 -41 -14 -15 15 -15 19 1 43 21 32 10 35 -15 4 -15 -19 -20 -21 -34 -9 -15 13 -15 16 5 37 12 13 20 26 17 29 -2 3 -14 -5 -25 -18z"
                        fill="#0C2462"
                        className="svg-elem-11"
                    ></path>

                    <path
                        d="M910 1024 c0 -4 8 -16 17 -26 16 -18 16 -20 -5 -40 -17 -18 -24 -19 -36 -9 -8 7 -21 11 -27 9 -6 -2 14 -23 45 -46 58 -44 73 -46 34 -5 l-22 23 21 21 c20 20 22 20 47 4 37 -25 32 -4 -6 26 -61 46 -68 50 -68 43z"
                        fill="#0C2462"
                        className="svg-elem-12"
                    ></path>

                    <path
                        d="M762 841 c-36 -45 -42 -63 -12 -36 20 18 21 17 59 -13 22 -18 46 -32 53 -32 12 1 -2 15 -61 61 -23 19 -23 20 -6 39 10 11 14 20 8 20 -5 0 -24 -18 -41 -39z"
                        fill="#0C2462"
                        className="svg-elem-13"
                    ></path>

                    <path
                        d="M80 270 c0 -5 4 -10 10 -10 5 0 10 -30 12 -67 2 -42 8 -68 16 -71 9 -3 12 15 12 67 0 56 3 71 15 71 8 0 15 5 15 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z"
                        fill="#0C2462"
                        className="svg-elem-14"
                    ></path>

                    <path
                        d="M260 200 c0 -63 3 -80 14 -80 9 0 16 13 19 32 l3 33 13 -32 c16 -42 36 -44 28 -3 -4 16 -2 32 3 35 11 7 13 55 4 79 -4 11 -19 16 -45 16 l-39 0 0 -80z m60 30 c0 -20 -5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15 -30z"
                        fill="#0C2462"
                        className="svg-elem-15"
                    ></path>

                    <path
                        d="M462 203 c3 -73 5 -78 26 -81 40 -6 52 14 52 89 0 55 -3 69 -15 69 -12 0 -15 -14 -15 -65 0 -51 -3 -65 -15 -65 -12 0 -15 14 -15 65 0 38 -4 65 -10 65 -7 0 -10 -29 -8 -77z"
                        fill="#0C2462"
                        className="svg-elem-16"
                    ></path>

                    <path
                        d="M644 255 c-8 -34 1 -53 31 -65 27 -10 35 -40 10 -40 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -18 0 -18 -5 -5 -31 12 -21 50 -25 68 -7 7 7 12 25 12 41 0 22 -6 30 -30 38 -31 11 -43 49 -15 49 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 16 0 19 9 9 34 -4 10 -18 16 -40 16 -28 0 -35 -5 -40 -25z"
                        fill="#0C2462"
                        className="svg-elem-17"
                    ></path>

                    <path
                        d="M830 270 c0 -5 5 -10 10 -10 6 0 10 -30 10 -70 0 -56 3 -70 15 -70 12 0 15 14 15 70 0 56 3 70 15 70 8 0 15 5 15 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z"
                        fill="#0C2462"
                        className="svg-elem-18"
                    ></path>

                    <path
                        d="M1152 203 l3 -78 35 0 35 0 3 78 c2 64 0 77 -13 77 -12 0 -15 -13 -15 -65 0 -37 -4 -65 -10 -65 -6 0 -10 28 -10 65 0 52 -3 65 -15 65 -13 0 -15 -13 -13 -77z"
                        fill="#0C2462"
                        className="svg-elem-19"
                    ></path>

                    <path
                        d="M1347 274 c-4 -4 -7 -22 -7 -40 0 -27 5 -35 25 -40 24 -6 37 -44 15 -44 -5 0 -10 5 -10 10 0 6 -7 10 -16 10 -10 0 -14 -7 -12 -22 2 -19 9 -23 38 -23 33 0 35 2 38 38 3 32 0 38 -22 47 -25 9 -38 50 -16 50 6 0 10 -7 10 -15 0 -8 7 -15 16 -15 10 0 14 7 12 23 -2 16 -11 23 -33 25 -17 2 -34 0 -38 -4z"
                        fill="#0C2462"
                        className="svg-elem-20"
                    ></path>

                    <path
                        d="M1940 200 l0 -81 38 3 37 3 0 75 0 75 -37 3 -38 3 0 -81z m58 37 c2 -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z m0 -70 c2 -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z"
                        fill="#0C2462"
                        className="svg-elem-21"
                    ></path>

                    <path
                        d="M2130 200 l0 -80 40 0 c29 0 40 4 40 15 0 9 -9 15 -25 15 -18 0 -25 5 -25 20 0 13 7 20 20 20 11 0 20 5 20 11 0 6 -9 13 -20 16 -30 8 -26 43 5 43 14 0 25 5 25 10 0 6 -18 10 -40 10 l-40 0 0 -80z"
                        fill="#0C2462"
                        className="svg-elem-22"
                    ></path>

                    <path
                        d="M2316 264 c-14 -37 -6 -62 24 -73 31 -10 42 -41 15 -41 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -6 -15 -14 0 -21 26 -38 52 -34 19 2 24 10 26 44 3 37 0 42 -22 48 -17 4 -26 13 -26 26 0 23 18 27 26 5 9 -24 24 -17 24 10 0 22 -4 25 -34 25 -22 0 -36 -6 -40 -16z"
                        fill="#0C2462"
                        className="svg-elem-23"
                    ></path>

                    <path
                        d="M2500 270 c0 -5 5 -10 10 -10 6 0 10 -30 10 -70 0 -56 3 -70 15 -70 12 0 15 14 15 70 0 56 3 70 15 70 8 0 15 5 15 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10z"
                        fill="#0C2462"
                        className="svg-elem-24"
                    ></path>

                    <path
                        d="M2826 258 c3 -13 10 -29 14 -37 4 -8 0 -33 -11 -58 -15 -36 -16 -43 -3 -43 8 0 19 12 25 28 l11 27 10 -27 c5 -16 16 -28 24 -28 18 0 18 2 -3 44 -15 32 -15 38 0 76 13 34 13 40 1 40 -8 0 -18 -10 -23 -22 l-8 -22 -12 22 c-15 29 -34 29 -25 0z"
                        fill="#0C2462"
                        className="svg-elem-25"
                    ></path>

                    <path
                        d="M1670 124 c0 -14 4 -23 10 -19 6 3 10 15 10 26 0 10 -4 19 -10 19 -5 0 -10 -12 -10 -26z"
                        fill="#0C2462"
                        className="svg-elem-26"
                    ></path>
                </g>
            </svg>

        </div>
    )
}

export default LogoSvg;