import { Component } from "react";
import { Link } from "react-router-dom";

export class ImpexHome extends Component {
  render() {
    return (
      <>




        <section className="container-fluid p-0">
          <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">

                <video muted loop autoPlay id="myVideo">
                  <source src="../../assets/video/Aerial_view.mp4" type="video/mp4" />
                </video>

                <div className="carousel-caption content">
                  <div className="container" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-5">


                        <h1 className="display-4 text-white mb-4 animated slideInDown">
                          {/* WELCOME TO X LINEZ TRUST US , BEST  X   */}
                          Welcome Aboard <span>X Linez</span>
                        </h1>
                        <p className="fs-5 mb-4 pb-2 mx-sm-5 animated slideInDown">
                          Your Trusted Logistics Partner
                        </p>

                        <Link className="my-btn animated slideInDown" to={'/contactus'}>Get Start With Us</Link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">

                <video muted loop autoPlay id="myVideo">
                  <source src="../../assets/video/Aerial_view.mp4" type="video/mp4" />
                </video>

                {/* <img className="w-100" src="../../assets/images/home-1.jpeg" alt="XLinez" /> */}                <div className="carousel-caption">
                  <div className="container" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-5">
                        <h1 className="display-4 text-white mb-4 animated slideInDown">
                          Embark on a Journey of Seamless Logistics
                        </h1>
                        <p className="fs-5 mb-4 pb-2 mx-sm-5 animated slideInDown">
                          Precision, Punctuality, and Personalized Service - That's X Linez
                        </p>
                        <Link className="my-btn animated slideInDown" to={'/contactus'}>Get Start With Us</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>



<section>
<div className="container-fluid mt-5 pt-5 ">
  <div className="row justify-content-center row-1">
    <div className="col-lg-5 col-md-7 col-sm-9 ">
      <p><b>STILL NOT CONVINCED? SO TAKE LOOK WHAT PEOPLE SAID IN THE MEDIA </b></p>
      <div id="carouselExampleIndicators" className="carousel slide d-flex" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to={0} />
          <li data-target="#carouselExampleIndicators" data-slide-to={1} className="active" />
          <li data-target="#carouselExampleIndicators" data-slide-to={2} />
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item">
            <div className="card border-0 ">
              <div className="card-body text-center ">
                <div className="row">
                  <div className="col">
                    <h5 className="card-text bold text-center">"I believe that one defines oneself by reinvention. To not be like your parents. To not be like your friends. To be yourself. To cut yourself out of stone." </h5>
                    <h6 className="mt-5"><b>Business Insider</b></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item active">
            <div className="card border-0 ">
              <div className="card-body text-center ">
                <div className="row">
                  <div className="col">
                    <h5 className="card-text bold text-center">"I believe that one defines oneself by reinvention. To not be like your parents. To not be like your friends. To be yourself. To cut yourself out of stone." </h5>
                    <h6 className="mt-5"><b>Business Insider</b></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card border-0 ">
              <div className="card-body text-center ">
                <div className="row">
                  <div className="col">
                    <h5 className="card-text bold text-center">"I believe that one defines oneself by reinvention. To not be like your parents. To not be like your friends. To be yourself. To cut yourself out of stone." </h5>
                    <h6 className="mt-5"><b>Business Insider</b></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>





          <div className="container">
            <div className="row row-cols-1 row-cols-md-3 g-4 mt-5 pb-5 ">
              <div className="col ps-4 pe-4" data-aos="fade-down">
                <div className="card h-100" style={{ border: 'none' }}>
                  <img src="../../assets/images/g.jpeg" className="card-img-top" alt="thexlinez" />
                  <div className="card-body p-0 pt-3">
                    <h5 className="card-title" style={{ color: '#a40000', fontWeight: 'bold' }}>
                      Global Reach &amp; Local Expertise
                    </h5>
                    <p className="card-text" style={{ color: '#002462' }}>
                      Whether you're shipping goods across borders or within your city,
                      our extensive network ensures seamless connections to get your
                      cargo where it needs to be.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ps-4 pe-4" data-aos="fade-down">
                <div className="card h-100" style={{ border: 'none' }}>
                  <img src="../../assets/images/t.webp" className="card-img-top" style={{ WebkitTransition: '0.3s all ease-in-out', OTransition: '0.3s all ease-in-out', transition: '0.3s all ease-in-out' }} alt="thexlinez.com" />
                  <div className="card-body p-0 pt-3">
                    <h5 className="card-title" style={{ color: '#a40000', fontWeight: 'bold' }}>
                      Efficient Transportation
                    </h5>
                    <p className="card-text" style={{ color: '#002462' }}>
                      Utilizing the latest technology and a diverse fleet, we guarantee
                      timely and secure transportation of your goods, ensuring they
                      reach their destination in optimal condition.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ps-4 pe-4" data-aos="fade-down">
                <div className="card h-100" style={{ border: 'none' }}>
                  <img src="../../assets/images/c.webp" className="card-img-top" alt="The X Linez" />
                  <div className="card-body p-0 pt-3">
                    <h5 className="card-title" style={{ color: '#a40000', fontWeight: 'bold' }}>
                      Supply Chain Optimization
                    </h5>
                    <p className="card-text" style={{ color: '#002462' }}>
                      Our experts analyze and optimize your supply chain, identifying
                      opportunities for efficiency and cost savings. From procurement to
                      distribution, we streamline every aspect of your logistics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>





        <header className="header">
          <h1>WHAT I DO</h1>
        </header>
        <section className="content">
          <div className="block">
            <img src="import-icon.png" alt="Import Icon" />
            <h2>IMPORT</h2>
            <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
            <a href="#">Read More</a>
          </div>
          <div className="block">
            <img src="buy-icon.png" alt="Buy Icon" />
            <h2>BUY</h2>
            <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
            <a href="#">Read More</a>
          </div>
          <div className="block">
            <img src="export-icon.png" alt="Export Icon" />
            <h2>EXPORT</h2>
            <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
            <a href="#">Read More</a>
          </div>
          <div className="block">
            <img src="export-icon.png" alt="Export Icon" />
            <h2>EXPORT</h2>
            <p>Fusce nec tellus sed augue semper porta. Mauris massa, Vestibulum lacinia arcu eget nulla.</p>
            <a href="#">Read More</a>
          </div>
        </section>

      </>
    )
  }
}