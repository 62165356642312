import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class ImpexFooter extends Component {

  render = () => {
    return (
      <>


        <div className="pg-footer">
          <footer className="footer">
            <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
              <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z" />
            </svg>
            <div className="footer-content">
              <div className="footer-content-column">
                <div className="footer-logo">
                  <a className="footer-logo-link" href="/impex/home">
                    <img className='w-75' src="../assets/images/impexlogo.png" alt="" />
                  </a>
                </div>
                <div className="footer-menu">
                  <h2 className="footer-menu-name"> Get Started</h2>
                  <ul id="menu-get-started" className="footer-menu-list">
                    <li className="menu-item menu-item-type-post_type menu-item-object-product">
                      <a href="#">Start</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-product">
                      <a href="#">Documentation</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-product">
                      <a href="#">Installation</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-content-column">
                <div className="footer-menu">
                  <h2 className="footer-menu-name"> Company</h2>
                  <ul id="menu-company" className="footer-menu-list">
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="#">Contact</a>
                    </li>
                    <li className="menu-item menu-item-type-taxonomy menu-item-object-category">
                      <a href="#">News</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="#">Careers</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu">
                  <h2 className="footer-menu-name"> Legal</h2>
                  <ul id="menu-legal" className="footer-menu-list">
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                      <a href="#">Privacy Notice</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="#">Terms of Use</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-content-column">
                <div className="footer-menu">
                  <h2 className="footer-menu-name"> Quick Links</h2>
                  <ul id="menu-quick-links" className="footer-menu-list">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                      <a target="_blank" rel="noopener noreferrer" href="#">Support Center</a>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                      <a target="_blank" rel="noopener noreferrer" href="#">Service Status</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="#">Security</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="#">Blog</a>
                    </li>
                    <li className="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                      <a href="#">Customers</a></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                      <a href="#">Reviews</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-content-column">
                <div className="footer-call-to-action">
                  <h2 className="footer-call-to-action-title"> Let's Chat</h2>
                  <p className="footer-call-to-action-description"> Have a support question?</p>
                  <a className="footer-call-to-action-button button" href="/impex" target="_self"> Get in Touch </a>
                  <a className="footer-call-to-action-button button" style={{backgroundColor:"#25D366"}} href="https://api.whatsapp.com/send?phone=919566650400" target="_self"><i className="bi bi-whatsapp w-125"></i> Whatsapp </a>
                </div>
                <div className="footer-call-to-action">
                  <h2 className="footer-call-to-action-title"> You Call Us</h2>
                  <p className="footer-call-to-action-link-wrapper"> <a className="footer-call-to-action-link" href="tel:+9195666 50400" target="_self">(+91) 95666 50400 </a></p>
                </div>
              </div>

            </div>
            <div className="footer-copyright">
              <div className="footer-copyright-wrapper">
                <p className="footer-copyright-text">
                  <a className="footer-copyright-link" href="#" target="_self"> ©2024.| x Linez | All rights reserved. </a>
                </p>
              </div>
            </div>
          </footer>
        </div>

     </>
    )
  }
}
